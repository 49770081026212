<template>
    <Modal-aviso 
        title="CARO USUÁRIO!"
        :secondarybuttontext="textSecondaryButton"
        primarybuttontext="IR PARA MEUS PRODUTOS"
        :primarybuttoncallback="goToMyProducts" 
        :secondarybuttoncallback="closeModal" 
        v-if="showModal"
    > 
        <p v-if="type == 'after_register'">
            Cadastramos {{ pluralize('seu', 'seus') }}  {{ pluralize('produto', 'produtos') }}  e percebemos que {{ pluralize('ele', 'eles') }} {{ pluralize('pode', 'podem') }} ganhar a garantia adicional!
        </p>
        <p v-else>
            Notamos que você ainda não viu o vídeo que pode disponibilizar a garantia adicional para o seu produto!
        </p>

        <p class="text-blue">
            Para receber o benefício é muito fácil:
        </p>
        <p v-if="type == 'after_register'">
            <span>1</span> Acesse sua conta, vá até o link "Meus produtos" e visualize {{ pluralize('o', 'os') }} {{ pluralize('vídeo', 'vídeos') }} {{ pluralize('técnico', 'técnicos') }} {{ pluralize('do', 'dos') }} {{ pluralize('seu', 'seus') }} {{ pluralize('equipamento', 'equipamentos') }}.
        </p>
        <p v-else>
          <span>1</span> Visualize {{ pluralize('o', 'os') }} {{ pluralize('vídeo', 'vídeos') }} {{ pluralize('técnico', 'técnicos') }} {{ pluralize('do', 'dos') }} {{ pluralize('seu', 'seus') }} {{ pluralize('equipamento', 'equipamentos') }}.
        </p>
        <p>
            <span>2</span> Aguarde a aprovação do cadastro pela Atlas.
        </p>
    </Modal-aviso>
</template>
<script>
   export default {
        name: 'modal-aviso-garantia-disponivel',
        props: {
            type: {
                type: String,
                default: 'padrao'
            },
            openModal: {
                default: false
            },
            plurals: {
                default: false,
            },
            showSecondaryButton: {
                default: true
            }
        },
        data: function () {
            return {
                showModal: false,
                showPlurals: '',
                textSecondaryButton: this.showSecondaryButton ? 'OK' : ''
            };
        },
       watch: {
           openModal: function (val) {
               this.showModal = val
           },
       },
      methods: {
          closeModal: function () {
              this.showModal = false
          },
          goToMyProducts: function () {
              this.showModal = false
              window.location.href = _ROOT_PATH + '/clube-powertech/meus-produtos'
          },
          pluralize( singulary, plularize) {
            return (this.plurals >0) ? plularize : singulary;
          }
      }
   }
</script>